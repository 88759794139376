<template>
    <div class="index">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="crumb">
            <el-breadcrumb-item :to="{ path: $route.query.path }">{{$route.query.title}}</el-breadcrumb-item>
            <el-breadcrumb-item>文章详情</el-breadcrumb-item>
        </el-breadcrumb>
        <h3 class="titleIndex">{{info.title}}</h3>
        <div class="date">{{info.updateTime}}</div>
        <div v-html='info.content' class="content"></div>
    </div>
</template>
<script>
import {apiGetArticeDetail} from '@/api/arcticle'
export default {
    name: 'articleDetail',
    data(){
        return{
            id: '',
            info:{}
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.getInfo(this.id)
    },
    methods:{
        getInfo(id){
            apiGetArticeDetail({id}).then(res=>{
                if(res.code == 200){
                    this.info  = res.data
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.index{
    width: 1200px;
    margin: 0 auto;
    background-color: #f5f6f7;
}
.crumb{
    line-height: 50px;
}
.titleIndex{
    line-height: 40px;
    font-size: 16px;
}
.date{
    line-height: 30px;
    color: #999;
}
::v-deep.content{
    p{
        text-align: left;
        line-height: 26px;
    }
}
</style>